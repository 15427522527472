import React from "react";
import Seo from "../components/seo";
import LayoutWork from "../components/layouts/layoutWork";

const NotFoundPage = () => (
  <LayoutWork>
    <Seo title="404: Not found" />
    <div className="w-full mx-auto text-xl tracking-wide text-center">
    <h1 className="font-casloni pt-40">Error 404</h1>
    <p className="font-casloni">The page you are looking for can't be found.</p>
    </div>
  </LayoutWork>
);

export default NotFoundPage;
